import styles from "./mission.module.scss"
import Button from "@mui/material/Button";
import React from "react";
import {Link} from "@mui/material";

export const MissionModule = () => {
    return (
        <div className={styles.missionContainer}>
            <span className={styles.h3}>We believe the world can work better</span>
            <Link href={"https://dropbox.tech/"} target="_blank" rel="noopener noreferrer" id={styles.ctaButtonLinkContainer}>
                <Button onClick={() => {}} id={styles.ctaButton} style={{height: 'auto'}}>
                    <div id={styles.ctaButtonContent}>
                        <span id={styles.ctaButtonContent}>Read more</span>
                    </div>
                </Button>
            </Link>
        </div>
    )
}