import React, {useEffect} from "react";

import {useAppDispatch} from "./store/hooks";
import {windowActions, windowSizeFromPx} from "./store/window";
import {Column} from "./components/layout/column";
import {TopBar} from "./components/navigation/topBar";
import {Footer} from "./components/surfaces/footer";
import {useGetRepositoriesQuery} from "./store/github";

export const Main = ({children}: {
    children: React.ReactNode
}) => {
    useGetRepositoriesQuery()

    const dispatch = useAppDispatch()

    useEffect(() => {
        function getWindowDimensions() {
            const {innerWidth: width, innerHeight: height} = window;
            return {width, height};
        }

        function handleResize() {
            const {width} = getWindowDimensions()
            const windowSize = windowSizeFromPx(width)
            dispatch(windowActions.setWindowSize(windowSize))
        }

        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);


    return (
        <Column width="100%" justifyContent="flex-start" alignItems="flex-start">
            <header>
                <TopBar/>
            </header>

            {children}

            <footer>
                <Footer/>
            </footer>
        </Column>
    )
}