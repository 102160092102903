import styles from "./popular.success.module.scss"
import Button from "@mui/material/Button";
import {Link} from "@mui/material";
import {Repository} from "../../../../entities/github";
import projectLogos from "../../../../hardcoded/projectLogos";
import React from "react";

export const SuccessPopularModule = ({projects}: { projects: Repository[] }) => {
    return (
        <div className={styles.popularContainer}>
            <div className={styles.h3}>
                <span>Featured Projects</span>
            </div>

            {projects.map(project => (

                <Link key={project.id} href={project.url} target="_blank" rel="noopener noreferrer"
                      id={styles.projectLinkContainer}>
                    <ProjectCard key={project.id} project={project}/>
                </Link>
                )
            )}

            <Link href={"/projects"} id={styles.ctaButtonLinkContainer}>
                <Button variant="outlined" id={styles.ctaButton}>
                    <h4>View all</h4>
                </Button>
            </Link>
        </div>
    )
}

const ProjectCard = ({project, hideImage = false}: { project: Repository, hideImage?: boolean }) => {

    const projectLogo = !hideImage ? projectLogos[project.id] : null

    return (
        <div className={styles.cardContainer}>
            {projectLogo ? (
                <div className={styles.logoContainer}>
                    <img src={projectLogo} alt={project.name} width={80} height={80}/>
                </div>

            ) : null}

            <div className={styles.detailsContainer}>
                <span id={styles.projectOwner}>{project.owner}</span>
                <span id={styles.projectName}>{project.name}</span>
                <span>{project.description}</span>
            </div>

        </div>
    )
}