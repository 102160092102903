import styles from "./app.module.scss";
import React from "react";
import {Main} from "./main";
import StoreProvider from "./store/StoreProvider";


export default function RootLayout({children}: {
    children: React.ReactNode
}) {
    return (
        <html lang="en">
        <body className={styles.main}>
        <StoreProvider>
            <Main>
                {children}
            </Main>
        </StoreProvider>
        </body>
        </html>
    )
}


