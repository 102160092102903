import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import RootLayout from "./layout";
import {Home} from "./features/landing";
import Projects from "./features/projects";
import "./global.scss";

import '@fontsource/space-grotesk';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/projects" element={<Projects/>}/>
            </Routes>
        </Router>
    );
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <RootLayout>
            <App/>
        </RootLayout>
    </React.StrictMode>
);


