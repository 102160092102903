import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export enum Size {
    xs = 0,
    sm = 600,
    md = 900,
    lg = 1200,
    xl = 1536
}

export const windowSizeFromPx = (px: number) => {
    if (px < 600) return Size.xs
    if (px < 900) return Size.sm
    if (px < 1200) return Size.md
    if (px < 1536) return Size.lg
    return Size.xl
}

const initialState = {
    size: Size.md
}

const slice = createSlice({
    name: 'window',
    initialState,
    reducers: {
        setWindowSize(state, action: PayloadAction<Size>) {
            state.size = action.payload
        },
    }
})

export const {actions: windowActions, reducer: windowReducer} = slice

export const selectWindowSize = createSelector(
    [(state: RootState) => state.window || initialState],
    window => window.size
)