import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface SearchState {
    searchQuery: string;
}

const searchSliceInitialState: SearchState = {
    searchQuery: ""
}

export const searchSlice = createSlice({
    name: 'search',
    initialState: searchSliceInitialState,
    reducers: {
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
        },
    },
});

export const {setSearchQuery} = searchSlice.actions;

export const selectSearchQuery = (state: RootState) => state.search.searchQuery;