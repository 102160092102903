import {LoadingPopularModule} from "./loading";
import {SuccessPopularModule} from "./success";
import {useGetPopularRepositoriesQuery} from "../../../store/github";

export const PopularModule = () => {
    const {data, isLoading} = useGetPopularRepositoriesQuery()

    if (isLoading) {
        return (
            <LoadingPopularModule/>
        )
    } else if (data) {
        return (
            <SuccessPopularModule projects={data}/>
        )
    }

    return (<div/>)
}