import {Octokit} from "@octokit/rest";
import {createAppAuth} from "@octokit/auth-app";
import {APP_ID, CLIENT_SECRET, GITHUB_PRIVATE_KEY, INSTALLATION_ID} from "../../env";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {Repository} from "../../entities/github";
import {
    CachedGitHubData,
    LanguageColors,
    LanguageData,
    LanguageIndex,
    RepositoryData,
    TopicData,
    TopicIndex
} from "./CachedGitHubData";
import {getRandomLanguageColor} from "./LanguageColor";

const REPOSITORY_DATA = "repositoryData"
const TOPIC_DATA = "topicData"
const LANGUAGE_DATA = "languageData"
const TOPIC_INDEX = "topicIndex"
const LANGUAGE_INDEX = "languageIndex"
const LAST_MODIFIED = "lastModified"
const LANGUAGE_COLORS = "languageColors"
const POPULAR_REPOSITORIES = "popularRepositories"
const CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour in milliseconds

const appOctokit = new Octokit({
    authStrategy: createAppAuth,
    auth: {
        appId: APP_ID,
        privateKey: GITHUB_PRIVATE_KEY,
        clientSecret: CLIENT_SECRET,
        installationId: INSTALLATION_ID
    },
});


const query = `
  query ($org: String!, $after: String) {
    
    organization(login: $org) {
      repositories(first: 100, after: $after) {
        pageInfo {
            endCursor
            hasNextPage
        } 
        nodes {
          id
          name
          description
          stargazerCount
          forkCount
          url
          homepageUrl
          languages(first: 10) {
            nodes {
              name
              color
            }
          }
          repositoryTopics(first: 10) {
            nodes {
              topic {
                id
                name
              }
            }
          }
          owner {
            login
          }
        }
      }
    }
  }
`;

export const fetchRepositories = createAsyncThunk('github/fetchRepositories', async () => {

    const cachedData: CachedGitHubData = getCachedGitHubData()

    if (Object.keys(cachedData.repositories).length > 0) {
        return cachedData;
    }

    try {
        let shouldFetch = true
        let after: string | null = null

        const allRepositories = []

        while (shouldFetch) {
            const response = await appOctokit.graphql({
                query,
                org: 'dropbox',
                after: after
            }) as any;

            allRepositories.push(...response.organization.repositories.nodes)

            if (response.organization.repositories.pageInfo.hasNextPage) {
                after = response.organization.repositories.pageInfo.endCursor as string
            } else {
                shouldFetch = false
            }
        }

        const repositories: Repository[] = allRepositories.map((node: any) => ({
            id: node.id,
            name: node.name,
            description: node.description,
            stargazerCount: node.stargazerCount,
            forkCount: node.forkCount,
            url: node.url,
            homepageUrl: node.homepageUrl,
            languages: node.languages?.nodes ?? [],
            contributors: [],
            logoUrl: undefined,
            topics: node.repositoryTopics?.nodes?.map((topicNode: any) => topicNode.topic) ?? [],
            owner: node.owner?.login ?? undefined,
        }));


        return createCachedGitHubData(repositories)

    } catch (e) {
        return {
            repositories: {},
            languages: {},
            topics: {},
            topicRepositoryIndex: {},
            languageRepositoryIndex: {},
            languageColors: {}
        } as CachedGitHubData
    }
});


const getCachedGitHubData = (): CachedGitHubData => {
    const repositoryDataStr = localStorage.getItem(REPOSITORY_DATA)
    const languageDataStr = localStorage.getItem(LANGUAGE_DATA)
    const topicDataStr = localStorage.getItem(TOPIC_DATA)
    const lastModifiedStr = localStorage.getItem(LAST_MODIFIED)
    const languageIndexStr = localStorage.getItem(LANGUAGE_INDEX)
    const languageColorsStr = localStorage.getItem(LANGUAGE_COLORS)
    const topicIndexStr = localStorage.getItem(TOPIC_INDEX)
    const popularRepositoriesStr = localStorage.getItem(POPULAR_REPOSITORIES)

    if (repositoryDataStr && lastModifiedStr && languageIndexStr && languageColorsStr && topicIndexStr && languageDataStr && topicDataStr && popularRepositoriesStr) {
        const lastModified: number = JSON.parse(lastModifiedStr)
        const repositoryData: RepositoryData = JSON.parse(repositoryDataStr);

        if (Object.values(repositoryData).length > 0 && Date.now() - lastModified < CACHE_EXPIRY) {
            const languageIndex: LanguageIndex = JSON.parse(languageIndexStr)
            const languageColors: LanguageColors = JSON.parse(languageColorsStr)
            const topicIndex: TopicIndex = JSON.parse(topicIndexStr)
            const languageData: LanguageData = JSON.parse(languageDataStr)
            const topicData: TopicData = JSON.parse(topicDataStr)
            const popularRepositories: string[] = JSON.parse(popularRepositoriesStr)

            return {
                repositories: repositoryData,
                languages: languageData,
                topics: topicData,
                languageColors: languageColors,
                languageRepositoryIndex: languageIndex,
                topicRepositoryIndex: topicIndex,
                popularRepositories
            }
        }

    }

    return {
        repositories: {},
        languages: {},
        topics: {},
        languageColors: {},
        languageRepositoryIndex: {},
        topicRepositoryIndex: {},
        popularRepositories: []
    }
}

const createCachedGitHubData = (repositories: Repository[]): CachedGitHubData => {
    const repositoryData: RepositoryData = {};
    const topicIndex: TopicIndex = {};
    const languageIndex: LanguageIndex = {};
    const languageColors: LanguageColors = {}

    const languageData: LanguageData = {}
    const topicData: TopicData = {}

    repositories.forEach(repo => {
        repositoryData[repo.id] = repo;

        repo.topics.forEach(topic => {
            if (!topicIndex[topic.name]) {
                topicIndex[topic.name] = [];
            }
            topicIndex[topic.name].push(repo.id);
            topicData[topic.id] = topic;
        });

        repo.languages.forEach(language => {
            if (!languageIndex[language.name]) {
                languageIndex[language.name] = [];

                languageColors[language.name] = getRandomLanguageColor().toString()
            }
            languageIndex[language.name].push(repo.id);
            languageData[language.name] = language
        });
    });

    const popularRepositories = repositories.sort((a, b) => b.stargazerCount - a.stargazerCount).slice(0, 5).map(repo => repo.id);

    localStorage.setItem(LAST_MODIFIED, Date.now().toString())
    localStorage.setItem(REPOSITORY_DATA, JSON.stringify(repositoryData));
    localStorage.setItem(LANGUAGE_DATA, JSON.stringify(languageData));
    localStorage.setItem(TOPIC_DATA, JSON.stringify(topicData));
    localStorage.setItem(TOPIC_INDEX, JSON.stringify(topicIndex));
    localStorage.setItem(LANGUAGE_INDEX, JSON.stringify(languageIndex));
    localStorage.setItem(LANGUAGE_COLORS, JSON.stringify(languageColors))
    localStorage.setItem(POPULAR_REPOSITORIES, JSON.stringify(popularRepositories))

    return {
        repositories: repositoryData,
        topics: topicData,
        languages: languageData,
        languageColors,
        languageRepositoryIndex: languageIndex,
        topicRepositoryIndex: topicIndex,
        popularRepositories
    }
};


