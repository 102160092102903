
export enum LanguageColor {
    Gray = '#9BA3AF',
    Rose = '#FB7185',
    Pink = '#F471B5',
    Fuchsia = '#BF6ACF',
    Purple = '#B57FEF',
    Violet = '#9F84EC',
    Indigo = '#7B85EB',
    Blue = '#5FA4F9',
    Sky = '#37BAF5',
    Cyan = '#2CADC4',
    Teal = '#2CD4BF',
    Emerald = '#35B286',
    Green = '#49DE80',
    Lime = '#9ADA37',
    Yellow = '#F8CB15',
    Amber = '#FBBE24',
    Orange = '#FB923C',
    Red = '#F16F6F',
}

export const getRandomLanguageColor = (): LanguageColor => {
    const colors = Object.values(LanguageColor);
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex] as LanguageColor;
}