import {createSelector, createSlice} from "@reduxjs/toolkit";
import {LanguageColors, LanguageData, LanguageIndex, RepositoryData, TopicData, TopicIndex} from "./CachedGitHubData";
import {fetchRepositories} from "./fetchRepositories";
import {RootState} from "../store";
import popularProjects from "../../hardcoded/popularProjects";
import {useAppDispatch, useAppSelector} from "../hooks";
import {useEffect} from "react";
import {Language, Repository, Topic} from "../../entities/github";
import {setLanguageFilters, setTopicFilters} from "../filters";


interface GitHubState {
    repositories: RepositoryData,
    topics: TopicData,
    languages: LanguageData,
    topicRepositoryIndex: TopicIndex,
    languageRepositoryIndex: LanguageIndex,
    languageColors: LanguageColors,
    popularRepositories: string[]
}

export const githubSlice = createSlice({
    name: 'github',
    initialState: {
        repositories: {},
        topics: {},
        languages: {},
        topicRepositoryIndex: {},
        languageRepositoryIndex: {},
        languageColors: {},
        popularRepositories: []
    } as GitHubState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(fetchRepositories.fulfilled, (state, action) => {
            state.repositories = action.payload.repositories
            state.topicRepositoryIndex = action.payload.topicRepositoryIndex
            state.languageColors = action.payload.languageColors
            state.languageRepositoryIndex = action.payload.languageRepositoryIndex
            state.topics = action.payload.topics
            state.popularRepositories = action.payload.popularRepositories
            state.languages = action.payload.languages
        })
    },
});

export const selectPopularRepositories = createSelector(
    (state: RootState) => state,
    state => {

        if (popularProjects.length >= 5) {
            const popularRepositories = popularProjects.map(id => state.github.repositories[id]).filter(repo => repo)
            if (popularRepositories.length >= 5) {
                return popularRepositories
            }
        }

        return state.github.popularRepositories?.map(id => state.github.repositories[id]) ?? []
    }
);

export const selectRepositories = createSelector(
    (state: RootState) => state.github.repositories,
    repositories => Object.values(repositories)
)

export const selectLanguages = createSelector(
    (state: RootState) => state.github.languages,
    languages => Object.values(languages)
)

export const selectTopics = createSelector(
    (state: RootState) => state.github.topics,
    topics => Object.values(topics)
)

export const useGetPopularRepositoriesQuery = () => {
    const dispatch = useAppDispatch();
    const popularRepositories = useAppSelector(selectPopularRepositories);

    useEffect(() => {
        dispatch(fetchRepositories());
    }, [dispatch]);

    return {data: popularRepositories, isLoading: popularRepositories.length === 0};
};

export const useGetRepositoriesQuery = () => {
    const dispatch = useAppDispatch();
    const repositories = useAppSelector(selectRepositories);

    useEffect(() => {
        dispatch(fetchRepositories());
    }, [dispatch]);

    return {data: repositories, isLoading: repositories.length === 0};
};

export const selectFilteredRepositories = createSelector(
    [(state: RootState) => state],
    state => {
        const searchQuery = state.search.searchQuery
        const repositories = state.github.repositories
        const languages = state.filters.languages
        const topics = state.filters.topics

        if (languages.length == 0 && topics.length == 0 && !searchQuery) {
            return Object.values(repositories)
        }

        if (searchQuery && languages.length == 0 && topics.length == 0) {
            return Object.values(repositories).filter(repo => {
                return repo.name?.includes(searchQuery) || repo.description?.includes(searchQuery)
            })
        }

        const matchingRepositories: Repository[] = []

        languages.flatMap(language => state.github.languageRepositoryIndex[language.name]).forEach(repositoryId => {
            const repo = state.github.repositories[repositoryId]
            if (repo.name?.includes(searchQuery) || repo.description?.includes(searchQuery)) {
                matchingRepositories.push(repo)
            }
        })

        topics.flatMap(topic => state.github.topicRepositoryIndex[topic.name]).forEach(repositoryId => {
            const repo = state.github.repositories[repositoryId]
            if (repo.name?.includes(searchQuery) || repo.description?.includes(searchQuery)) {
                matchingRepositories.push(repo)
            }
        })

        return matchingRepositories;
    }
);

export const selectLanguageFilters = (state: RootState) => state.filters.languages;
export const selectTopicFilters = (state: RootState) => state.filters.topics;


export const filterRepositories = createSelector(
    selectRepositories,
    selectLanguageFilters,
    selectTopicFilters,
    (repositories, selectedLanguages, selectedTopics) => {
        return repositories.filter((repo) => {
            const hasSelectedLanguages = selectedLanguages.every((language) =>
                repo.languages.some((repoLanguage) => repoLanguage.name === language.name)
            );
            const hasSelectedTopics = selectedTopics.every((topic) =>
                repo.topics.some((repoTopic) => repoTopic.name === topic.name)
            );
            return hasSelectedLanguages && hasSelectedTopics;
        });
    }
);

export const useFilterRepositories = () => {
    const dispatch = useAppDispatch();

    const setSelectedLanguages = (languages: Language[]) => {
        dispatch(setLanguageFilters(languages));
    };

    const setSelectedTopics = (topics: Topic[]) => {
        dispatch(setTopicFilters(topics));
    };

    return {
        setSelectedLanguages,
        setSelectedTopics,
    };
};

export const selectLanguageStatus = (name: string,) => createSelector(
    [(state: RootState) => state.filters.languages],
    languages => {
        return languages.some(language => language.name == name)
    }
)


export const selectTopicStatus = (name: string,) => createSelector(
    [(state: RootState) => state.filters.topics],
    topics => {
        return topics.some(topic => topic.name == name)
    }
)
