import {Language, Topic} from "../entities/github";
import {createSlice} from "@reduxjs/toolkit";

const filtersSliceInitialState: {
    languages: Language[];
    topics: Topic[];
} = {
    languages: [],
    topics: []
}

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: filtersSliceInitialState,
    reducers: {
        setLanguageFilters: (state, action) => {
            state.languages = action.payload;
        },
        setTopicFilters: (state, action) => {
            state.topics = action.payload;
        },

        addLanguage: (state, action) => {
            state.languages.push(action.payload)
        },
        removeLanguage: (state, action) => {
            state.languages = state.languages.filter(l => l.name != action.payload.name)
        },
        addTopic: (state, action) => {
            state.topics.push(action.payload)
        },
        removeTopic: (state, action) => {
            state.topics = state.topics.filter(l => l.id != action.payload.id)
        }
    },
});

export const {setLanguageFilters, setTopicFilters, addLanguage, removeLanguage, addTopic, removeTopic} = filtersSlice.actions;
