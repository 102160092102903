import * as React from "react"
export const GitHubIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className="icon"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 3c-4.972 0-9 4.028-9 9 0 3.978 2.58 7.348 6.155 8.541.447.083.613-.193.613-.436 0-.215-.006-.779-.011-1.53-2.503.541-3.033-1.205-3.033-1.205-.41-1.039-1-1.32-1-1.32-.818-.558.06-.547.06-.547.901.066 1.382.928 1.382.928.8 1.376 2.105.978 2.619.746.082-.58.314-.978.569-1.205-1.995-.22-4.094-.994-4.094-4.442 0-.983.348-1.784.928-2.414-.094-.232-.403-1.144.083-2.381 0 0 .757-.243 2.475.922A8.734 8.734 0 0 1 12 7.354a8.766 8.766 0 0 1 2.254.303c1.718-1.165 2.475-.922 2.475-.922.492 1.237.183 2.154.089 2.381.574.63.922 1.431.922 2.414 0 3.459-2.105 4.216-4.11 4.442.32.277.613.83.613 1.669 0 1.204-.011 2.171-.011 2.47 0 .243.16.519.619.43C18.425 19.348 21 15.978 21 12.005A8.999 8.999 0 0 0 12 3Z"
            clipRule="evenodd"
        />
    </svg>
)
