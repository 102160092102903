import React, {useEffect} from "react";
import {Column} from "../../components/layout/column";
import styles from "../../app.module.scss";
import {Hero} from "./hero";
import {PopularModule} from "./popular";
import {MissionModule} from "./mission";

import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/store";
import {fetchRepositories} from "../../store/github/fetchRepositories";

export const Home: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchRepositories());
    }, [dispatch]);

    return (
        <Column id={styles.homePage}>
            <Hero/>
            <PopularModule/>
            <MissionModule/>
        </Column>
    )
}

