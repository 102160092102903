import styles from "./hero.module.scss"
import spectrum from '../../../assets/img/spectrum.svg'
import React from "react";
import {Column} from "../../../components/layout/column";


export const Hero = () => (
    <Column alignItems="center" width={"100vw"} className={styles.heroContainer} justifyContent="flex-start">
        <OverTitle>Our mission is to design a more enlightened way of working</OverTitle>
        <H1>
            <Column>
                <Span>Dropbox Loves</Span>
                <Span>Open Source 💙</Span>
            </Column>
        </H1>
        <Description>We participate in the open source community by using open source software
            internally and open sourcing our own projects</Description>
    </Column>
);

const Span = ({children}: {
    children: React.ReactNode
}) => (
    <span
        style={{
            backgroundImage: `url(${spectrum})`,
            backgroundRepeat: 'repeat-x',
            backgroundSize: '40vw 0.15em',
            backgroundPosition: 'left bottom 5px',
        }}
    >
      {children}
    </span>
)

const H1 = ({children}: {
    children: React.ReactNode
}) => {
    return (
        <span className={styles.h1}>{children}</span>
    )
}

const OverTitle = ({children}: {
    children: React.ReactNode
}) => {
    return (
        <span className={styles.overText}>{children}</span>
    )
}

const Description = ({children}: {
    children: React.ReactNode
}) => {
    return (
        <span className={styles.description}>{children}</span>
    )
}