import styles from "../success/popular.success.module.scss"
import Button from "@mui/material/Button";
import {Box, Link} from "@mui/material";

export const LoadingPopularModule = () => {

    return (
        <div className={styles.popularContainer}>
            <div className={styles.h3}>
                <span>Featured Projects</span></div>
            {[1, 2, 3, 4, 5].map(id => <Box key={id} className={styles.loadingCardContainer}/>)}

            <Link href={"/projects"} id={styles.ctaButtonLinkContainer}>
                <Button variant="outlined" id={styles.ctaButton}>
                    <h4>View all</h4>
                </Button>
            </Link>
        </div>
    )
}