import styles from './footer.module.scss'
import footerItems from "./footer_items.json"
import {Link} from "@mui/material";

export const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            {footerItems.map(({title, items}) => {
                return (
                    <FooterSection key={title} title={title} items={items}/>
                )
            })}
        </div>
    )
}

const FooterItem = ({href, title}: {
    href: string,
    title: string
}) => {
    return (
        <Link target="_blank"
              rel="noopener noreferrer"
              href={href}
              className={styles.footerItem}>
            {title}
        </Link>
    )
}

const FooterSection = ({title, items}: {
    title: string,
    items: {
        title: string,
        href: string
    }[]
}) => {
    return (
        <div className={styles.footerSection}>
            <span className={styles.footerHeader}>
                {title}
            </span>

            {items.map(({title, href}) => {
                return (
                    <FooterItem key={href} href={href} title={title}/>
                )
            })}
        </div>
    )
}