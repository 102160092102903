import React from "react";
import {Stack} from "@mui/system";
import {Property} from "csstype";


export const Row = ({children, justifyContent = "start", alignItems = "center", width = "auto", className, id}: {
    children: React.ReactNode,
    justifyContent?: Property.JustifyContent,
    alignItems?: Property.AlignItems,
    width?: Property.Width,
    className?: string,
    id?: string
}) => {
    return (
        <div
            id={id}
            className={className}
            style={{display: "flex", flexDirection: "row", justifyContent, alignItems, width}}>
            {children}
        </div>
    )
}