import styles from './navigation.module.scss'
import dropbox from '../../assets/img/dropbox.svg';
import {Row} from "../layout/row";
import {GitHubIcon} from "../icons/GitHubIcon";
import React from "react";
import {Link} from "@mui/material";


export function TopBar() {

    return (
        <Row justifyContent="space-between" width={"calc(100vw-32px)"} id={styles.navBar}>
            <Row>
                <Link href={"/"}
                      style={{display: "flex", flexDirection: "row", alignItems: "center", textDecoration: "none"}}>
                    <img src={dropbox} alt="Dropbox" width={50} height={50}/>
                    <span className={styles.navTitle}>Dropbox Open Source</span>
                </Link>
            </Row>


            <Row alignItems="center">
                <Link href={"https://github.com/dropbox"} target="_blank" rel="noopener noreferrer">
                    <GitHubIcon width={35} height={35} className={styles.navButton}/>
                </Link>
            </Row>

        </Row>
    );

}