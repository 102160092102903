import React from "react";
import {Property} from "csstype";


export const Column = ({children, justifyContent = "start", alignItems = "start", width = "auto", height = "auto", className, id}: {
    children: React.ReactNode,
    justifyContent?: Property.JustifyContent,
    alignItems?: Property.AlignItems,
    width?: Property.Width,
    height?: Property.Height,
    className?: string,
    id?: string
}) => {
    return (
        <div
            id={id}
            className={className}
            style={{display: "flex", flexDirection: "column", justifyContent, alignItems, width, height}}>
            {children}
        </div>
    )
}