import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {Language, Repository, Topic} from "../../entities/github";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectWindowSize} from "../../store/window";
import styles from "./projects.module.scss";
import {Dialog, IconButton, Link} from "@mui/material";
import FilterIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import {
    selectFilteredRepositories,
    selectLanguageFilters,
    selectLanguages, selectTopicFilters,
    selectTopics,
    useFilterRepositories
} from "../../store/github";
import {fetchRepositories} from "../../store/github/fetchRepositories";
import {AppDispatch} from "../../store/store";
import {selectSearchQuery, setSearchQuery} from "../../store/search";
import {addLanguage, addTopic, removeLanguage, removeTopic} from "../../store/filters";

export default function Projects() {

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchRepositories());
    }, [dispatch]);

    return (
        // <Page/>
        <Repositories/>
    )
}


const removeDuplicatesByProperty = <T extends {
    [key: string]: any
}, K extends keyof T>(array: T[], property: K): T[] => {
    const seen = new Set();
    return array.filter(item => {
        const value = item[property];
        return seen.has(value) ? false : seen.add(value);
    });
};

const SearchComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const searchQuery = useAppSelector(selectSearchQuery);
    const [inputValue, setInputValue] = useState(searchQuery);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            dispatch(setSearchQuery(inputValue));
        }
    };

    return (
        <div id={styles.searchContainer}>
            <IconButton type="submit" aria-label="search">
                <SearchIcon/>
            </IconButton>
            <input
                id={styles.searchInput}
                onInput={handleInputChange}
                onKeyDown={handleKeyPress}
                placeholder="Search..."
            />
        </div>
    );
};

const FilterDialog: React.FC<{
    open: boolean,
    onClose: () => void
}> = ({open, onClose}) => {
    const languages = useAppSelector(selectLanguages);
    const topics = useAppSelector(selectTopics);
    const {setSelectedLanguages, setSelectedTopics} = useFilterRepositories();

    const handleLanguageFilterChange = (selectedLanguages: Language[]) => {
        setSelectedLanguages(selectedLanguages);
    };

    const handleTopicFilterChange = (selectedTopics: Topic[]) => {
        setSelectedTopics(selectedTopics);
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{sx: {backgroundColor: "transparent", borderRadius: 0}}}>
            <div id={styles.filtersDialogContainer}>
                <div id={styles.filtersContainer}>
                    <div>
                        <span id={styles.filterByHeading}>Filter by</span>
                    </div>
                    <div className={styles.filtersDivider}/>
                    <div id={styles.languageFiltersContainer}>
                        <div>
                            <span id={styles.languageFiltersHeading}>Languages</span>
                        </div>
                        <div id={styles.languageFiltersScrollContainer}>
                            {removeDuplicatesByProperty(languages, "name").map(language => (
                                <FilterItem key={language.name} item={language} onSelect={handleLanguageFilterChange}/>
                            ))}
                        </div>
                    </div>
                    <div className={styles.filtersDivider}/>
                    <div id={styles.topicFiltersContainer}>
                        <div>
                            <span id={styles.languageFiltersHeading}>Tags</span>
                        </div>
                        <div id={styles.languageFiltersScrollContainer}>
                            {removeDuplicatesByProperty(topics, "name").map(topic => (
                                <FilterItem key={topic.name} item={topic} onSelect={handleTopicFilterChange}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const FilterItem: React.FC<{
    item: Language | Topic,
    onSelect: (selectedItems: any[]) => void
}> = ({item}) => {
    const dispatch = useAppDispatch();
    const selectedLanguages = useAppSelector(selectLanguageFilters);
    const selectedTopics = useAppSelector(selectTopicFilters);

    const isSelected = isLanguage(item)
        ? selectedLanguages.some(language => language.name === item.name)
        : selectedTopics.some(topic => topic.id === item.id);

    const handleClick = () => {
        if (isLanguage(item)) {
            dispatch(addLanguage(item))
        } else {
            dispatch(addTopic(item))
        }
    };

    if (isSelected) {
        return null;
    }

    return (
        <div id={isLanguage(item) ? styles.languageFilterContainer : styles.topicFilterContainer}
             onClick={handleClick}>
            {isLanguage(item) && (
                <div>
                    <Circle color={item.color && item.color != "" ? item.color : "#0164e3"}/>
                </div>
            )}
            <div>{isLanguage(item) ? item.name : `#${item.name}`}</div>
        </div>
    );
};

const SelectedFilters: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedLanguages = useAppSelector(selectLanguageFilters);
    const selectedTopics = useAppSelector(selectTopicFilters);

    const handleClick = (item: Language | Topic) => {
        if (isLanguage(item)) {
            dispatch(removeLanguage(item))
        } else {
            dispatch(removeTopic(item))
        }
    }

    return (
        <div id={styles.selectedFiltersContainer}>
            {[...selectedTopics, ...selectedLanguages].map(item => (
                <div
                    key={isLanguage(item) ? item.name : item.id}
                    id={isLanguage(item) ? styles.selectedLanguageChip : styles.selectedTopicChip}
                    onClick={() => {
                        handleClick(item)
                    }}
                >
                    {isLanguage(item) ? item.name : item.name}
                    <CloseIcon id={styles.closeIcon}/>
                </div>
            ))}
        </div>
    );
};

const RepositoryCard: React.FC<{
    project: Repository,
    hideImage?: boolean,
    containerClassName: string,
    detailsClassName: string
}> = ({
          project,
          hideImage = false,
          containerClassName,
          detailsClassName
      }) => {
    return (
        <div className={containerClassName}>
            {project.logoUrl && !hideImage && (
                <div className={styles.logoContainer}>
                    <img src={project.logoUrl} alt={project.name} width={80} height={80}/>
                </div>
            )}
            <div className={detailsClassName}>
                <span id={styles.projectOwner}>{project.owner}</span>
                <span id={styles.projectName}>{project.name}</span>
                <span>{project.description}</span>
            </div>
        </div>
    );
};


const WideFiltersContainer: React.FC = () => {
    const dispatch = useAppDispatch()

    return (
        <div id={styles.filtersContainer}>
            <SearchComponent/>
            <div style={{height: 24}}/>
            <div>
                <span id={styles.filterByHeading}>Filter by</span>
            </div>
            <div id={styles.filtersDivider}/>
            <div id={styles.languageFiltersContainer}>
                <div>
                    <span id={styles.languageFiltersHeading}>Languages</span>
                </div>
                <div id={styles.languageFiltersScrollContainer}>
                    {removeDuplicatesByProperty(useAppSelector(selectLanguages), "name").map(language => (
                        <FilterItem key={language.name} item={language} onSelect={() => {
                            dispatch(addLanguage(language))
                        }}/>
                    ))}
                </div>
            </div>
            <div id={styles.filtersDivider}/>
            <div id={styles.topicFiltersContainer}>
                <div>
                    <span id={styles.languageFiltersHeading}>Tags</span>
                </div>
                <div id={styles.languageFiltersScrollContainer}>
                    {removeDuplicatesByProperty(useAppSelector(selectTopics), "name").map(topic => (
                        <FilterItem key={topic.name} item={topic} onSelect={() => {
                            dispatch(addTopic(topic))
                        }}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

const CompactFiltersContainer = ({setFilterOpen}: {
    setFilterOpen: () => void
}) => {
    const projects = useAppSelector(selectFilteredRepositories);

    return (
        <div id={styles.compactFiltersContainer}>
            <SearchComponent/>

            <div style={{height: 24}}/>

            <div id={styles.compactResultsHeadingContainer}>
                <div id={styles.resultsCountContainer}>
                    <span>{projects.length} result{projects.length === 1 ? null : 's'}</span>
                </div>

                <div>
                    <FilterIcon onClick={() => setFilterOpen()} id={styles.filterIcon}/>
                </div>
            </div>
        </div>
    )
}

const Repositories: React.FC = () => {
    const windowSize = useAppSelector(selectWindowSize);
    const projects = useAppSelector(selectFilteredRepositories);
    const [isFilterOpen, setFilterOpen] = useState(false);

    const handleFilterToggle = () => {
        setFilterOpen(!isFilterOpen);
    };

    return (
        <div id={windowSize <= 600 ? styles.compactProjectsPageContainer : styles.wideProjectsPageContainer}>

            {windowSize > 600 ? <WideFiltersContainer/> : <CompactFiltersContainer setFilterOpen={handleFilterToggle}/>}

            <div id={windowSize <= 600 ? styles.resultsContainerCompact : styles.resultsContainer}>
                <SelectedFilters/>

                {windowSize > 600 ? (
                    <div id={styles.resultsCountContainer}>
                        <span>{projects.length} result{projects.length === 1 ? null : 's'}</span>
                    </div>
                ) : null}

                {projects.map(project => (
                    <Link key={project.id} href={project.url} target="_blank" rel="noopener noreferrer"
                          id={styles.projectLinkContainer}>

                        <RepositoryCard
                            key={project.id}
                            project={project}
                            hideImage={windowSize <= 600}
                            containerClassName={windowSize <= 600 ? styles.compactProjectContainer : styles.wideProjectContainer}
                            detailsClassName={windowSize <= 600 ? styles.compactProjectDetailsContainer : styles.wideProjectDetailsContainer}
                        />
                    </Link>
                ))}

            </div>

            {
                windowSize <= 600 && (
                    <div style={{width: "1-"}}>
                        <FilterDialog open={isFilterOpen} onClose={() => setFilterOpen(false)}/>
                    </div>
                )
            }

        </div>
    )
        ;
};

const Circle: React.FC<{
    color: string,
    size?: string
}> = ({color, size = "18px"}) => {
    const style = {
        backgroundColor: color,
        width: size,
        height: size,
        borderRadius: '50%',
    };

    return <div style={style}/>;
};

const isLanguage = (obj: any): obj is Language => {
    return 'color' in obj;
};
