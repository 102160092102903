import {configureStore} from '@reduxjs/toolkit'
import {windowReducer} from "./window";
import {githubSlice} from "./github";
import {searchSlice} from "./search";
import {filtersSlice} from "./filters";


export const makeStore = () => {
    return configureStore({
        reducer: {
            github: githubSlice.reducer,
            window: windowReducer,
            filters: filtersSlice.reducer,
            search: searchSlice.reducer,
        },
    })
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']